.Tab {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Tab > * {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
}
