.container {
  width: 100vw;
  height: 4em;
  background-color: #24221c;
  padding: 0 2em;
  display: flex;
  flex-direction: row;
  align-items:center;
  position: fixed;
  top: 0;
  z-index: 5;
}

.actions {
  flex-grow: 100;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

.userButton {
  overflow: hidden;
}

.actions > * {
  margin-right: 1em;
}

.title {
  margin-right: 2em;
}

.title:hover {
  cursor: pointer;
}

.link {
  font-size: 2em;
  margin-right: 1em;
  text-decoration-line: underline !important;
}