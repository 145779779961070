.container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-items: center;
    width: fit-content;
    margin: auto;
    margin-top: 2em;
    margin-bottom: 2em;
}

.userHeader img {
    width: 7em;
    height: 7em;
    margin-right: 1em;
    border-radius: 10%;
}

.tags {
    min-height: 2.5em;
    max-width: 90%;
}

@media(min-width: 900px) {
    .tags {
        max-width: 65%;
    }
}