.upload {
    padding: 2em;
    text-align: center;
}

.form {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 4em;
}

.panel {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.uploadInput {
    display: flex;
    flex-direction: column;
    height: 25em;
    width: 20em;
    text-align: center;
    background-color: #efefef;
    border: 3px solid #dfdfdf;
    border-style: dashed;
    margin-top: 2em;
}

.uploadInput:hover {
    cursor: pointer;
}

.uploadInputText {
    margin-top: 1em;
}

.uploadInputIcon {
    margin-top: 10em;
    align-self: center;
    justify-self: center;
}

.previewImage {
    margin-top: 2em;
    max-width: 10%;
    max-height: 10%;
    transform-origin: top left;
    transform: scale(10);
}

.previewImageClearButton {
    margin-top: 0.5em;
    margin-bottom: 1em;
}

.tabs {
    width: 100%
}

.rightSide {
    margin: 2em;
    display: flex;
    flex-direction: column; 
    text-align: left;  
    width: 100%; 
}

.rightSide > * {
    margin-bottom: 0.2em;
}

@media(min-width: 900px) {
    .upload {
        padding: 2em 25vw;
    }
    .panel {
        flex-direction: row;        
    }
}