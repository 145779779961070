.Profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
}

.Row {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 1em;
}

.RowText {
    font-size: 1.5em;
    margin-right: 1em;
}

.RowInput {
    margin-left: 1em;
}

.SubmitButton {
    margin: auto;
    margin-top: 1em;
}

.PictureInput {
    margin-top: 0.5em;
    display: inline-block;
    padding: 6px 12px;
    cursor: pointer;
    border-radius: 0.375rem;
    background: #EDF2f7;
    font-size: 1rem;
    font-weight: 600;
}

.PictureInput:hover {
    background: #E2E8F0;
}

input[type="file"] {
    display: none;
}