.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5em;
}

.content > * {
  margin-bottom: 2em;
}

.tokenInput {
  width: calc(240px + 3em);  
}