.Post {
  height: fit-content;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
}

.BoundingBox {
  height: 70vh;
  width: 95vw;
}

.PostImage {
  margin: auto;
  margin-top: 2em;
  max-width: 10%;
  max-height: 10%;
  transform-origin: top center;
  transform: scale(10);
}

.VotingButtons {
  display: flex;
  flex-direction: row;
  margin-top: 1em;
}

.VotingButtons > * {
  margin-left: 0.5em;
  margin-right: 0.5em;
  width: calc(140px + 2em);
  border: 2px solid black;
}

.Skeleton {
  margin: auto;
  width: 85vw;
  height: 45vh;
}

@media(min-width:900px) {
  .Post {
    height:90vh
  }

  .Skeleton {
    width: 65vw;
    height: 65vh;
  }
  
  .BoundingBox {
    width: 50vw;
  }

  .VotingButtons {
    margin-top: 3em;
  }
}