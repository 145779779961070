.FeedPost {
    margin-top: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PostImage {
    width: auto;
    max-width: 95vw;
    height: auto;
    max-height: 65vh;
}

.PostImage:hover {
    cursor: pointer;
}

.Buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 500;
}
  
.Buttons > * {
    margin: auto;
    margin-top: 1em;
    margin-left: 0.5em;
    margin-right: 0.5em;
    border: 2px solid transparent;
}

.Skeleton {
    width: 80vw;
    height: 40vh;
}

@media(min-width: 750px) {
    .Skeleton {
        width: 40vw;
        height: 50vh;
    }
    .BoundingBox {
        width: 65vw;
    }

    .Buttons > * {
        margin-top: 1em;
    }
}