.SignUp {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5em;  
}

.label {  
  margin-top: 1em;
  width: calc(240px + 3em);
}

input {
  width: calc(240px + 3em);
}

.submitButton {
    margin-top: 1em;
    width: 240px
}
